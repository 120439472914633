/* You can add global styles to this file, and also import other style files */

$gray: #A2B7C1 !default;
$gray_light: #F0F6F6 !default;
$yellow: #EDFF00 !default;
$card-border-radius: 10px;
$max-width: 1300px;

.card-container {
  max-width: $max-width;
  margin: auto;
}

.full-page {
    height: 100%;
    width: 100%;
    position: fixed;

    .full-content {
        height: 100%;
    }
}

.s-color-gray {
  color: $gray;
}

.form-item {
    height: calc(100% - 60px)
}

.s-bg-light {
    background: $gray_light;
}

.s-bg-gray {
  background: $gray;
}

.s-list-item-border {
    border-radius: $card-border-radius;
}
.s-grid {
    display: grid;
}

.s-gap-05 {
    gap: .5rem;
}

.s-gap-1 {
    gap: 1rem;
}

.s-gray-color {
    color: $gray;
}

.s-card-border-radius {
  border-radius: $card-border-radius;
}

.shipping-detail-card {
    display: grid;
    gap: 0.4rem;
    background: $gray_light;
    padding: 1rem;
    border-radius: $card-border-radius;

    // .title {
    //     line-height: 1rem;
    //     margin-bottom: 0;
    // }
    dl {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1rem;
    }

    dt {
        font-weight: 500;
        grid-column: 1;
    }

    dd {
        grid-column: 2;
    }
}

.sender-package-wrapper,
.send-document-wrapper,
.mail-room-wrapper {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.shipping-detail-price-bg {
    background: $gray;
    border-radius: $card-border-radius;
    font-size: 1.4rem;
    padding: 1rem;
    font-weight: bolder;
    .price {
        color: $yellow;
    }
}

.shipping-detail-list-item {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
    .item-accessory {
        grid-column: 1 / 3;
    }
    .options {
        font-size: .9rem;
    }
}

.cart-order-item-cover {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  .order-item-logo {
    display: flex;
    align-items: center;
    font-size: 50px;
  }
  .order-item {
    display: grid;
    grid-template-columns: 1fr auto;
    .title {
      font-size: large;
    }

    .order-item-info a {
      cursor: pointer;
    }
  }
}

.detail-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.accessories-container {
    grid-column: 1/3;
    display: grid;
    gap: 0.5rem;
}

.nav .nav-link {
    cursor: pointer;
}

.nav .nav-link.active {
    background: $gray_light;
    font-weight: 600;
}

.total {
  color: $yellow;
}

.s-separator {
  width: 1px;
  background: $gray;
  margin: 6px 0;
}
